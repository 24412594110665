import { useState } from "react"
import { onClickUrl } from "../utils";

export const SectionD = () => {
    const [content] = useState([
        {
            title: "Di Balik Pangkuan Ibu",
            link: ""
        },
        {
            title: "Menawan Kematian",
            link: "https://perkaramenulis.perkarahidup.com/menawan-kematian-8748be55c345"
        },
        {
            title: "Mengingkari Kematian",
            link: "https://perkaramenulis.perkarahidup.com/mengingkari-kematian-7852d90592bb"
        },
        {
            title: "Yang Agung Adalah Hari Ini",
            link: "https://mulainulislagi.medium.com/cerita-pendek-yang-agung-adalah-hari-ini-880b166596d8"
        },
        {
            title: "Untukmu Jantungku Untukku",
            link: "https://perkaramenulis.perkarahidup.com/untukmu-jantungku-untukku-da4c8b383761"
        },
    ])

    return (
        <div className="h-full snap-center flex flex-row relative justify-center lg:justify-start" id="writingProject">
            {/* <p className="absolute text-2xl lg:text-6xl 2xl:text-6xl select-none py-4 px-2">
                <span className="underline px-8 hover:text-[#7672ec] transition duration-500 ease-in-out">Writing Project</span> <br/>
            </p> */}
            <div className="flex flex-auto lg:justify-end justify-center items-center">
                <div className="text-right h-[500px] hide-scroll-bar overflow-y-scroll snap-mandatory snap-y">
                    {content.map((item, index) => {
                        return (
                            <p 
                                key={index}
                                onClick={onClickUrl(item.link)}
                                className="h-1/3 flex justify-center lg:justify-end items-center snap-center text-[22px] sm:text-[28px] lg:text-[40px] xl:text-[60px] 2xl:text-[80px] hover:bg-[#7672ec] transition duration-300 ease-in-out text-gray-400 hover:text-white px-4 cursor-pointer select-none text-center">{item.title}</p>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}