export const SectionA = () => {
    return (
        <div className="h-full snap-center flex flex-col justify-center items-center" id="home">
            <div className="select-none text-[60px] md:text-[120px] xl:text-[160px] uppercase hover:text-[#7672ec] transition duration-1000 ease-in-out">
            <p>Manusia</p>
            <p className="transform translate-y-[-42px] md:translate-y-[-90px] xl:translate-y-[-120px]">Seketika</p>
            </div>
            <div className="min-w-[420px] md:min-w-[640px] xl:min-w-[1000px]">
            <div className="marquee select-none transform -translate-y-[70px] lg:-translate-y-[100px]">
                <p> 
                    <span className="hover:text-[#7672ec] transition duration-500 ease-in-out">💻 Fullstack Developer</span>
                    <span className="md:ml-8 xl:ml-16"></span> ⏺ <span className="md:ml-8 xl:ml-16"></span> 
                    <span className="hover:text-[#7672ec] transition duration-500 ease-in-out">✏️ Writer</span>
                    <span className="md:ml-8 xl:ml-16"></span> ⏺ <span className="md:ml-8 xl:ml-16"></span>
                    <span className="hover:text-[#7672ec] transition duration-500 ease-in-out">🎭 Actor</span>
                    <span className="md:ml-8 xl:ml-16"></span> ⏺ <span className="md:ml-8 xl:ml-16"></span>
                    <span className="hover:text-[#7672ec] transition duration-500 ease-in-out">🎬 Director</span>
                </p>
            </div>
            </div>
        </div>
    )
}