import { onClickUrl } from '../utils';
export const ProfileLink = () => {

    return (
        <div className="flex justify-center items-center bg-[#000] border-[#424242] border-t-[1px] lg:border-t-[0px] lg:border-l-[1px]">
            <div className="flex flex-row lg:flex-col">
                <button onClick={onClickUrl("https://www.instagram.com/joojujijoje/")} className="p-4 min-w-[54px] h-[54px] hover:bg-[#7672ec] transition duration-1000 ease-in-out">
                    <img className="w-full h-full" src={"https://i.imgur.com/JBZsjTq.png"} alt="instagram-icon"></img>
                </button>
                <button onClick={onClickUrl("https://github.com/razaqisama")} className="p-4 min-w-[54px] h-[54px] hover:bg-[#7672ec] transition duration-1000 ease-in-out">
                    <img className="w-full h-full" src={"https://i.imgur.com/eoOsXgt.png"} alt="github-icon"></img>
                </button>
                <button onClick={onClickUrl("https://www.linkedin.com/in/razaqisama/")} className="p-4 min-w-[54px] h-[54px] hover:bg-[#7672ec] transition duration-1000 ease-in-out">
                    <img className="w-full h-full" src={"https://i.imgur.com/mWgvKXW.png"} alt="linkedin-icon"></img>
                </button>
                <button onClick={onClickUrl("https://perkaramenulis.perkarahidup.com/")} className="p-4 min-w-[54px] h-[54px] hover:bg-[#7672ec] transition duration-1000 ease-in-out">
                    <img className="w-full h-full" src={"https://i.imgur.com/icYzkKu.png"} alt="medium-icon"></img>
                </button>
            </div>
        </div>
    )
}