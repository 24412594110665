import {Helmet} from 'react-helmet-async'
export const Head = () => {
    return (
        <Helmet>
            <link rel="icon" type="image/svg+xml" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%22256%22 height=%22256%22 viewBox=%220 0 100 100%22><rect width=%22100%22 height=%22100%22 rx=%2250%22 fill=%22%239013fe%22></rect><path d=%22M47.14 96.92L47.14 96.92Q60.00 96.92 66.12 91.62Q72.24 86.31 72.24 77.95L72.24 77.95Q72.24 70.60 67.54 66.12Q62.85 61.63 52.65 58.57L52.65 58.57L37.35 53.88Q30.21 51.63 24.40 48.67Q18.58 45.72 14.50 41.74Q10.42 37.76 8.28 32.35Q6.14 26.95 6.14 19.81L6.14 19.81Q6.14 3.69 18.07-5.59Q30.01-14.87 50.41-14.87L50.41-14.87Q58.77-14.87 65.91-13.55Q73.05-12.22 78.25-9.87Q83.46-7.53 86.41-4.37Q89.37-1.20 89.37 2.47L89.37 2.47Q89.37 5.94 87.64 8.49Q85.90 11.04 83.46 12.67L83.46 12.67Q78.36 8.79 70.09 6.04Q61.83 3.28 51.84 3.28L51.84 3.28Q40.62 3.28 34.50 7.77Q28.38 12.26 28.38 19.81L28.38 19.81Q28.38 25.93 32.66 29.70Q36.94 33.48 46.74 36.33L46.74 36.33L58.57 39.80Q75.70 44.90 85.19 53.77Q94.68 62.65 94.68 77.95L94.68 77.95Q94.68 86.11 91.51 92.94Q88.35 99.78 82.44 104.67Q76.52 109.57 67.65 112.22Q58.77 114.87 47.55 114.87L47.55 114.87Q38.17 114.87 30.42 113.24Q22.66 111.61 17.05 108.96Q11.44 106.30 8.38 102.84Q5.32 99.37 5.32 95.49L5.32 95.49Q5.32 91.41 7.77 88.56Q10.22 85.70 13.48 84.27L13.48 84.27Q18.38 88.76 26.85 92.84Q35.31 96.92 47.14 96.92Z%22 fill=%22%23000000%22></path></svg>" />
            <title>Manusia Seketika</title>
            <meta
                name="description"
                content="Manusia Seketika. Web Portfolio"
            />
            <meta name="keywords" content={"manusia,seketika,manusia seketika,portfolio,razaqisama"} />
        </Helmet>

    )
}