
import { 
  ProfileLink, 
  SectionA, 
  SectionB, 
  SectionC,
  SectionD,
  SectionE
} from './Components';

import {Head} from './Helmet';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const scrollIntoHome = (e) => {
    e.preventDefault();
    const homeElement = document.getElementById('home');
    homeElement.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollIntoWorks = (e) => {
    e.preventDefault();
    const worksElement = document.getElementById('developingProject');
    worksElement.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <HelmetProvider>
      <div className="flex flex-col lg:flex-row w-screen h-screen bg-[#1d1d1d] text-white font-mono">
        <Head />
        <div className="flex flex-row lg:flex-col justify-evenly items-center lg:border-r-[1px] border-r-[0px] border-b-[1px] lg:border-b-[0px] border-[#424242]">
          
          {/* WEBVIEW NAVIGATION*/}
          <div
            onClick={scrollIntoHome} 
            className="hidden lg:inline cursor-pointer text-center select-none hover:text-[#7672ec] transition duration-300 ease-in-out px-8">
            <p>H</p>
            <p>O</p>
            <p>M</p>
            <p>E</p>
          </div>
          <div
            onClick={scrollIntoWorks} 
            className="hidden lg:inline cursor-pointer text-center select-none hover:text-[#7672ec] transition duration-300 ease-in-out px-8">
            <p>W</p>
            <p>O</p>
            <p>R</p>
            <p>K</p>
            <p>S</p>
          </div>
          <div className="hidden lg:inline cursor-pointer text-center select-none hover:text-[#7672ec] transition duration-300 ease-in-out px-8">
            <p>A</p>
            <p>B</p>
            <p>O</p>
            <p>U</p>
            <p>T</p>
          </div>
          {/* WEBVIEW NAVIGATION*/}

          {/* MOBILE NAVIGATION*/}
          <div 
            onClick={scrollIntoHome}
            className="lg:hidden cursor-pointer text-center select-none hover:text-[#7672ec] transition duration-300 ease-in-out px-8 py-4">
            <p>Home</p>
          </div>
          <div 
            onClick={scrollIntoWorks}
            className="lg:hidden cursor-pointer text-center select-none hover:text-[#7672ec] transition duration-300 ease-in-out px-8 py-4">
            <p>Works</p>
          </div>
          <div className="lg:hidden cursor-pointer text-center select-none hover:text-[#7672ec] transition duration-300 ease-in-out px-8 py-4">
            <p>About</p>
          </div>
          {/* MOBILE NAVIGATION */}
        </div>
        {/* CONTENT */}

        <div className="flex-auto bg-[#000] overflow-y-auto h-screen hide-scroll-bar snap-mandatory snap-y">
          <SectionA />
          <SectionB />
          <SectionC />
          <SectionD />
          <SectionE />
        </div>
        {/* CONTENT */}

        <ProfileLink />
      </div>
    </HelmetProvider>

  );
}

export default App;
