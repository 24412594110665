import { onClickUrl } from '../utils';

import { useState } from 'react';

export const SectionE = () => {
    const [images] = useState([
        "https://i.imgur.com/gWuv0RA.jpg",
        "https://i.imgur.com/kTDuo7o.jpg",
        "https://i.imgur.com/8UC6S48.jpg",
        "https://i.imgur.com/sio1Zty.jpg",
        "https://i.imgur.com/uKIBkql.jpg",
        "https://i.imgur.com/wQQhuTP.jpg",
        "https://i.imgur.com/K3Ashs4.jpg",
        "https://i.imgur.com/aJZ5wuA.jpg",
        "https://i.imgur.com/cmkY30j.jpg",
        "https://i.imgur.com/B8EGauX.jpg",
        "https://i.imgur.com/DaiZLX3.jpg",
        "https://i.imgur.com/qNTjmbl.jpg",
        "https://i.imgur.com/AVPscn7.jpg",

    ]) 

    const [imagesIndex, setImagesIndex] = useState(0);

    const nextImages = (e) => {
        e.preventDefault();

        if(!(imagesIndex + 1 > images.length - 1)) {
            setImagesIndex(imagesIndex + 1);
        } else {
            setImagesIndex(0);
        }
    }

    const prevImages = (e) => {
        e.preventDefault();
        if(!(imagesIndex - 1 < 0)) {
            setImagesIndex(imagesIndex - 1);
        } else {
            setImagesIndex(images.length - 1);
        }
    }
    return (
        <div className="h-full snap-center flex justify-center items-center flex-col p-8" id="teaterProject">
            <div className="relative w-full max-h-[90%] flex flex-col justify-center items-center mb-4">
                <div className="flex flex-row w-full h-full overflow-hidden">
                    {images.map((item, index) => {
                        return (
                                <div key={index} className="w-full min-w-[100%] h-full flex flex-col justify-center items-center p-4 transition duration-300 ease-in-out"
                                    style={{
                                        transform: `translateX(-${imagesIndex * 100}%)`
                                    }}>
                                    <img src={item} alt="Sumur Tanpa Dasar" className="h-full"></img>
                                </div>
                        )
                    })}
                </div>
                <button 
                    className="absolute right-0"
                    onClick={nextImages}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 hover:text-[#7672ec] transition duration-500 ease-in-out" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </button>
                <button 
                    className="absolute left-0"
                    onClick={prevImages}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 hover:text-[#7672ec] transition duration-500 ease-in-out" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
                    </svg>
                </button>
            </div>
            <div className="flex flex-col lg:flex-row lg:space-x-4 lg:space-y-0 space-y-4">
                <button
                    onClick={onClickUrl("https://youtu.be/Ezt7B24t7Hc")} 
                    className="px-2 lg:px-8 lg:py-2 text-xs lg:text-xl border-2 hover:text-[#7672ec] hover:border-[#7672ec] transition duration-500 ease-in-out flex flex-row items-center justify-center space-x-2">
                    <p>Sumur Tanpa Dasar </p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="min-h-[1.5rem] min-w-[1.5rem] max-h-[1.5rem] max-w-[1.5rem]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>    
                </button>
                <button
                    onClick={onClickUrl("https://youtu.be/kKzAvagvnqo")} 
                    className="px-2 lg:px-8 lg:py-2 text-xs lg:text-xl border-2 hover:text-[#7672ec] hover:border-[#7672ec] transition duration-500 ease-in-out flex flex-row items-center justify-center space-x-2">
                    <p>V.S.A.I </p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="min-h-[1.5rem] min-w-[1.5rem] max-h-[1.5rem] max-w-[1.5rem]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>    
                </button>
                <button
                    onClick={onClickUrl("https://youtu.be/vDb7kDYzjrk")} 
                    className="px-2 lg:px-8 lg:py-2 text-xs lg:text-xl border-2 hover:text-[#7672ec] hover:border-[#7672ec] transition duration-500 ease-in-out flex flex-row items-center justify-center space-x-2">
                    <p>Domba Pengadilan </p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="min-h-[1.5rem] min-w-[1.5rem] max-h-[1.5rem] max-w-[1.5rem]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>    
                </button>
            </div>
        </div>
    )
}