export const SectionB = () => {
    return (
        <div className="h-full snap-center flex flex-col">
            <div className="flex flex-col lg:flex-row h-full pt-2 lg:pt-4 px-2 lg:px-4">
            <div className="relative flex justify-center flex-auto overflow-hidden min-w-[40%] h-3/4 lg:h-full">
                <img className="absolute h-full transform lg:scale-x-[200%] lg:scale-y-[200%] lg:translate-y-[300px] lg:translate-x-[120px] transition duration-300 ease-in-out" src={"https://i.imgur.com/JXuKJQU.jpg"} alt="profile-seketika"></img>
            </div>
            <div className="flex flex-auto justify-center items-center lg:justify-start text-xs lg:text-lg lg:px-8">
                <div className="select-none">
                <p className="text-3xl italic lg:self-start text-[#7672ec]">Who Am I? </p>
                <p className="pb-4">
                    Waktu SMA sempat ditanya "Apa cita-cita kamu?" Kemudian si Manusia Seketika ini tanpa pikir panjang menjawab "Jadi OP Warnet."
                    <br />
                    Tanpa disangka, hobi duduk di depan komputer membuatnya menjadi seorang Fullstack Developer sebagai profesi utamanya.
                    <br /> 
                    Akan tetapi, sesuatu yang berlebihan itu tidaklah baik. Maka dari itu, bergelut di bidang kesenian, terutama teater, dijadikan penyeimbang untuk kehidupan pikiran dan batin.
                </p>
                </div>
            </div>
            </div>
        </div>
    )
}