import { onClickUrl } from '../utils';

export const SectionC = () => {
    return (
        <div className="h-full snap-center flex flex-col" id="developingProject">
            {/* <div className="text-2xl lg:text-6xl 2xl:text-6xl select-none py-4 px-2 text-center lg:text-left">
                <p className="underline px-8 hover:text-[#7672ec] transition duration-500 ease-in-out">Developing Project</p>                
            </div> */}
            <div className="flex flex-col lg:flex-row flex-auto m-16 md:m-24 lg:m-36 space-y-2 lg:space-y-0 lg:space-x-2">
                <div className="flex flex-col flex-auto space-y-2">
                    <div className="group relative flex justify-center items-center flex-auto overflow-hidden">
                        <div className="absolute w-full h-full bg-black z-[99] opacity-40 group-hover:opacity-80 transition duration-500 ease-in-out">
                            <p className="opacity-0 group-hover:opacity-100 text-lg lg:text-4xl transition duration-500 ease-in-out text-[#7672ec] px-4 py-4">PinteDots</p>
                        </div>
                        <img src={"https://i.imgur.com/ezKNWUb.png"} alt="pintedots" className="absolute min-w-[100%] min-h-[100%]"></img>
                        <div className="flex flex-row space-x-4 w-full h-full justify-center items-center z-[999] transform translate-y-full group-hover:translate-y-0 transition duration-500 ease-in-out">
                            <button
                                onClick={onClickUrl("https://pintedotes.vercel.app/")} 
                                className="border-2 py-2 px-8 border-[#7672ec] text-[#7672ec] hover:text-white hover:bg-[#7672ec] transition duration-300 ease-in-out text-xl text-2xl">Demo</button>
                            <button 
                                onClick={onClickUrl("https://github.com/razaqisama/pintedotes")} 
                                className="border-2 py-2 px-8 border-[#7672ec] text-[#7672ec] hover:text-white hover:bg-[#7672ec] transition duration-300 ease-in-out text-xl text-2xl">Repo</button>
                        </div>
                    </div>
                    <div className="group relative flex justify-center items-center flex-auto lg:border-b-[0px] overflow-hidden">
                        <div className="absolute w-full h-full bg-black z-[99] opacity-40 group-hover:opacity-80 transition duration-500 ease-in-out">
                            <p className="opacity-0 group-hover:opacity-100 text-lg lg:text-4xl transition duration-500 ease-in-out text-[#7672ec] px-4 py-4">Game Tersulit Di Abad 21</p>
                        </div>
                        <img src={"https://i.imgur.com/N1j18jY.png"} alt="poogame" className="absolute min-w-[100%] min-h-[100%]"></img>
                        <div className="flex flex-row space-x-4 w-full h-full justify-center items-center z-[999] transform translate-y-full group-hover:translate-y-0 transition duration-500 ease-in-out">
                            <button
                                onClick={onClickUrl("https://razaqisama.github.io/")} 
                                className="border-2 py-2 px-8 border-[#7672ec] text-[#7672ec] hover:text-white hover:bg-[#7672ec] transition duration-300 ease-in-out text-xl text-2xl">Demo</button>
                            <button 
                                onClick={onClickUrl("https://github.com/razaqisama/razaqisama.github.io")} 
                                className="border-2 py-2 px-8 border-[#7672ec] text-[#7672ec] hover:text-white hover:bg-[#7672ec] transition duration-300 ease-in-out text-xl text-2xl">Repo</button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col flex-auto space-y-2">
                    <div className="group relative flex justify-center items-center flex-auto overflow-hidden">
                        <div className="absolute w-full h-full bg-black z-[99] opacity-40 group-hover:opacity-80 transition duration-500 ease-in-out">
                            <p className="opacity-0 group-hover:opacity-100 text-lg lg:text-4xl transition duration-500 ease-in-out text-[#7672ec] px-4 py-4">Creativent</p>
                        </div>
                        <img src={"https://i.imgur.com/NkdEgc6.png"} alt="creativent" className="absolute min-w-[100%] min-h-[100%]"></img>
                        <div className="flex flex-row space-x-4 w-full h-full justify-center items-center z-[999] transform translate-y-full group-hover:translate-y-0 transition duration-500 ease-in-out">
                            <button
                                onClick={onClickUrl("https://github.com/Team-1-RMT006")} 
                                className="border-2 py-2 px-8 border-[#7672ec] text-[#7672ec] hover:text-white hover:bg-[#7672ec] transition duration-300 ease-in-out text-xl text-2xl">Repo</button>
                        </div>
                    </div>
                    <div className="group relative flex justify-center items-center flex-auto overflow-hidden">
                        <div className="absolute w-full h-full bg-black z-[99] opacity-40 group-hover:opacity-80 transition duration-500 ease-in-out">
                            <p className="opacity-0 group-hover:opacity-100 text-lg lg:text-4xl transition duration-500 ease-in-out text-[#7672ec] px-4 py-4">Sugoku</p>
                        </div>
                        <img src={"https://i.imgur.com/mf0KS1A.png"} alt="subopu" className="absolute min-w-[100%] min-h-[100%]"></img>
                        <div className="flex flex-row space-x-4 w-full h-full justify-center items-center z-[999] transform translate-y-full group-hover:translate-y-0 transition duration-500 ease-in-out">
                            <button
                                onClick={onClickUrl("https://github.com/razaqisama/sugokuu")} 
                                className="border-2 py-2 px-8 border-[#7672ec] text-[#7672ec] hover:text-white hover:bg-[#7672ec] transition duration-300 ease-in-out text-xl text-2xl">Repo</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}